
























import Plyr from 'plyr'

import {
  defineComponent,
  onMounted,
  watchEffect,
  reactive,
  onUnmounted,
  ref
} from '@vue/composition-api'

export default defineComponent({
  name: 'ChilledMusic',
  props: {
    play: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { emit }) {
    const ready = ref(false)
    const chilledcow = reactive<{ video: Plyr | null }>({
      video: null
    })

    const onPlay = () => {
      if (!props.play) {
        emit('play')
      }
    }

    const onPause = () => {
      if (props.play) {
        emit('pause')
      }
    }

    const init = () => {
      chilledcow.video = new Plyr('#player', {
        youtube: {
          noCookie: true,
          rel: 0,
          showinfo: 0,
          iv_load_policy: 3,
          modestbranding: 1
        },
        quality: {
          default: 240,
          options: [240]
        }
      })
      chilledcow.video.on('error', init)
      chilledcow.video.on('play', onPlay)
      chilledcow.video.on('pause', onPause)
      chilledcow.video.on('ready', () => {
        ready.value = true
        if (props.play) {
          chilledcow.video?.play()
        }
      })
    }

    onMounted(init)

    onUnmounted(() => {
      chilledcow.video?.off('error', init)
      chilledcow.video?.off('play', onPlay)
      chilledcow.video?.off('pause', onPause)
      chilledcow.video?.destroy()
    })

    watchEffect(() => {
      props.play ? chilledcow.video?.play() : chilledcow.video?.pause()
    })

    return {
      ready
    }
  }
})
